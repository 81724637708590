<template>
   <div class="app-container">
    <el-row :gutter="30">
      <el-col
        :span="5" style=" min-height: 300px; overflow-y: auto; padding-top: 15px; box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%); ">
        <div class="head-container tree-container">
          <el-tree
            :data="data"
            :expand-on-click-node="false"
            ref="tree"
            class="tree tree-line"
            accordion
            :indent="0"
            :props="defaultProps"
            font-size="50"
            @node-click="handleNodeClick"
            default-expand-all/>
        </div>
      </el-col>
      <!--服务实例-->
      <el-col :span="19">
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input
                  v-model="regionId"
                  placeholder="地区号"
                  size="medium"
                  clearable
                  @keyup.enter.native="search">
                </el-input>
            </el-form-item>

            <el-form-item>
                <el-button @click="search" type="primary" icon="el-icon-search" size="small">搜索</el-button>
                <el-button @click="refresh" icon="el-icon-refresh" size="small">重置</el-button>  
            </el-form-item>
            <el-form-item>
            <el-button type="primary"  @click="dialogVisible = true" size="medium"  v-if="hasAuth('sys:prov:add')" >新增</el-button>
          </el-form-item>
        </el-form>

        <!-- 添加弹窗 -->
        <el-dialog title="添加省市区" 
          :visible.sync="dialogVisible" 
          width="600px"
          :before-close="handleClose"
          :close-on-click-modal="false">
          <el-form
            :model="editForm"
            ref="editForm"
            :rules="editFormRules"
            label-width="100px"
            class="demo-editForm">
            <el-form-item label="上级地区号" prop="supbak"  label-width="100px" style="width: 90%">
              <el-input v-model="editForm.supbak" placeholder="请输入上级地区号"  autocomplete="off"></el-input>
            </el-form-item>
          
            <el-form-item label="地区名称" prop="regionName"  label-width="100px" style="width: 90%">
              <el-input v-model="editForm.regionName" placeholder="请输入添加的市/区"  autocomplete="off"></el-input>
            </el-form-item> 

            <el-form-item label="地区邮编" prop="regionCode"  label-width="100px" style="width: 90%">
              <el-input v-model="editForm.regionCode" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item
              label="地区级别:"
              prop="regionLevel"
              label-width="100px"
              style="margin-top: 20px">
              <el-radio-group v-model="editForm.regionLevel">
                <el-radio label="1">省</el-radio>
                <el-radio label="2">市</el-radio>
                <el-radio label="3">区/县</el-radio>
              </el-radio-group>
        </el-form-item>

          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
            <el-button @click="resetForm('editForm')">取消</el-button>
          </div>
       </el-dialog>
      
        <el-table
            :data="tableData"
            :default-expand-all="false"  
            style="width: 150%"
            row-key="regionId"
            border
            lazy
            size="small"
            height="700px"
            :load="getChildrens"
            :row-style="{height:'10px'}"
            :cell-style="{padding:'1px 0'}"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" >

            <el-table-column
                prop="regionId"
                label="地区号"
                width="320px">
            </el-table-column>

            <el-table-column
                prop="regionName"
                label="地区名称"
                width="320px">
            </el-table-column>

            <el-table-column
                prop="regionCode"
                label="地区编码"
                width="320px">
            </el-table-column>

            <!-- <el-table-column
                prop="regionLevel"
                label="地区级别"
                width="260px">
            </el-table-column> -->

            <el-table-column
                prop="icon"
                width="320px"
                label="操作"
                align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="editHandler(scope.row.regionId)" v-if="hasAuth('sys:prov:edit')" >编辑</el-button >
                    <el-divider direction="vertical"></el-divider>
                    <template>
                        <el-popconfirm
                            title="确认删除？"
                            @confirm="delHandle(scope.row.regionId)">
                            <el-button type="text" slot="reference" v-if="hasAuth('sys:prov:delete')" >删除</el-button>
                        </el-popconfirm>
                    </template>
            </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
  </template>
  
  <script>
  import api from "../../api/user";
  export default {

    data() {
      return {
        regionId:'',
        editForm: {},
        editFormRules: {
        supbak: [{ required: true, message: "请输入上级地区号", trigger: "blur" }],
        regionName: [{ required: true, message: "请输入地区名称", trigger: "blur" }],
        regionCode: [{ required: true, message: "请选输入地区编码", trigger: "blur" }],
        regionLevel:[{ required: true, message: "请选输入地区级别", trigger: "blur" }]
      },
        data:[],
        dialogVisible: false,
        regionCode:'',
        // searchForm: {
        //   regionId:'',
        // },
        // total: 20,
        // size: 10,
        // current: 1,
        tableData: [],
        defaultProps: {
          children: "children",
          label: "regionName"
        },
      };
    },
    created() {
      
      api.getListCity().then((res) => {
        this.tableData = res.data.data.result;
      })
       api.ProvTree().then((res) => {
         this.data = res.data.data.result;
       });

      api.queryprvoById().then((res)=>{
        this.data = res.data.data.result;
      });

       
    },
    methods: {
      //获得全部省
      getProv() {
        api.ProvTree().then((res) => {
          this.alIdOptions = res.data.data.result;
        });
      },
      //保存
      submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let status = this.editForm.regionId ? "update" : "save";
          api.saveProv(status, this.editForm).then((res) => {
            this.$notify({
              title: "prefect",
              message: "操作成功",
              position: "bottom-right",
              type: "success",
              onClose: () => {
                this.getListCity();
              },
            });
            this.dialogVisible = false;
            this.refresh();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      },
      //获得列表
      getListCity() {
        api.getListCity(this.regionCode,this.regionId).then((res) => {
          this.tableData = res.data.data.result;
        });
      },
        //编辑
        editHandler(regionId) {
          this.regionId = regionId
          api.queryprvoById(regionId).then(res => {
          this.editForm = res.data.data.result;
        });
        this.dialogVisible = true;
      },
      //取消
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.dialogVisible = false;
        this.editForm = {};
      },
      handleClose() {
        this.resetForm("editForm");
        this.dialogVisible = false;
        
        this.editForm = {};
      },
         //懒加载方法
     getChildrens(tree, treeNode, resolve) {
            console.log(tree,treeNode)
            this.Params.regionId = tree.regionId;
            this.getListCity(this.params).then((res) =>{
              tree.children = res.data;
              this.initData(tree.children);
              resolve(tree.children)
            })

      },
  
      //搜索
        search() {
           this.getListCity();
        },
     //重置
      refresh() {
        this.regionId = "",
          this.getListCity();
      },
     
        //点击某个省显示和市的菜单
        handleNodeClick(data) {
          this.regionCode = data.regionCode
          this.getListCity();
         },
      //删除
      delHandle(regionId) {
        api.delete(regionId).then((res) => {
          this.$notify({
            title: "prefect",
            message: res.data.message,
            position: "bottom-right",
            type: res.data.code === 200 ? "success" : "error",
          });
          this.getListCity();
        });
      },
    },
   
  }

  </script>
  
  <style scoped lang="scss">
  .prov_content {
    width: 100%;
    height: 87vh;
    .left {
      margin-left: 20px;
      padding-top: 20px;
      border-radius: 5px;
      height: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      overflow: auto;
    }
    .right {
      margin-left: 30px;
      padding-top: 20px;
      border-radius: 5px;
      height: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      overflow: auto;
    }
  }
  .el-pagination {
    float: right;
    margin-top: 22px;
  }
  </style>
  <style lang="scss">
  .tree-line {
    .el-tree-node {
      position: relative;
      padding-left: 16px; // 缩进量
    }
    .el-tree-node__children {
      padding-left: 16px; // 缩进量
    }
  
    // 竖线
    .el-tree-node::before {
      content: "";
      height: 100%;
      width: 1px;
      position: absolute;
      left: -3px;
      top: -26px;
      border-width: 1px;
      border-left: 1px dashed #52627c;
    }
    // 当前层最后一个节点的竖线高度固定
    .el-tree-node:last-child::before {
      height: 38px; // 可以自己调节到合适数值
    }
  
    // 横线
    .el-tree-node::after {
      content: "";
      width: 24px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 12px;
      border-width: 1px;
      border-top: 1px dashed #52627c;
    }
  
    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    & > .el-tree-node::after {
      border-top: none;
    }
    & > .el-tree-node::before {
      border-left: none;
    }
  
    // 展开关闭的icon
    .el-tree-node__expand-icon {
      font-size: 16px;
      // 叶子节点（无子节点）
      &.is-leaf {
        color: transparent;
        // display: none; // 也可以去掉
      }
    }
  }
  </style>
  
  